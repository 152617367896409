import { WrappedFetch } from './wrappedFetch';
import { LinkBuilder } from '../util/LinkBuilder';
import '../eCrypt.min';
import { VendorSearchType } from '../types/VendorSearchType';
import { SubscriptionFormCode } from '../subscription-forms';
import { CardType } from '@property-folders/components/dragged-components/CardLogo';

export const TITLE_PRICE_STR = ((import.meta.env.VITE_SAILIS_PRICE_TITLE_CENTS ?? 1700) / 100).toLocaleString('en-au', { style: 'currency', currency: 'aud' }).substring(1);
export const TITLE_PRICE_CENTS = Number(import.meta.env.VITE_SAILIS_PRICE_TITLE_CENTS ?? 1700);
export const VENDOR_PRICE_STR = ((import.meta.env.VITE_SAILIS_PRICE_PROPRIETOR_CENTS ?? 270) / 100).toLocaleString('en-au', { style: 'currency', currency: 'aud' }).substring(1);
export const VENDOR_PRICE_CENTS = Number(import.meta.env.VITE_SAILIS_PRICE_PROPRIETOR_CENTS ?? 270);
export const API_CHARGE_PRICE_STR = ((import.meta.env.VITE_SAILIS_PRICE_API_CENTS ?? 540) / 100).toLocaleString('en-au', { style: 'currency', currency: 'aud' }).substring(1);
export const API_CHARGE_PRICE_CENTS = Number(import.meta.env.VITE_SAILIS_PRICE_API_CENTS ?? 540);

export class LegacyApi {
  static async checkForStoredPayment(entityId: string): Promise<StoredCard | NoStoredCard> {
    return (await WrappedFetch.json<StoredCard | NoStoredCard>(LinkBuilder.legacyApi(`/payment/${entityId}/stored`))) ?? { available: false };
  }

  static async makePayment(entityId: string, payment: Payment): Promise<string> {
    const eCryptKey = import.meta.env.VITE_EWAY_ENCRYPT_KEY;

    if (payment.card) {
      payment.card.cvn = (window as any).eCrypt.encryptValue(payment.card.cvn, eCryptKey);
      payment.card.number = (window as any).eCrypt.encryptValue(payment.card.number, eCryptKey);
    }

    const req = (await WrappedFetch.json<{token: string}>(LinkBuilder.legacyApi(`/payment/${entityId}`), {
      method: 'POST',
      body: JSON.stringify(payment)
    }));
    if (!req?.token) {
      throw new Error('payment failed');
    }

    return req.token;
  }

  static async ajax<T extends BaseAjaxResponse = { success: boolean }>(action: string, formData: Record<string, string | undefined>, signal?: AbortSignal) {
    const fdata = new FormData();
    for (const key of Object.keys(formData)) {
      const value = formData[key];
      if (value === undefined) continue;
      fdata.set(key, value);
    }
    const response = await WrappedFetch.bare(`${LinkBuilder.ajax}?action=${action}`, {
      method: 'POST',
      body: fdata,
      signal
    });
    const body = await response.json() as T;

    if (!body?.success) {
      throw new Error('unsuccessful ajax');
    }

    return body as T;
  }

  static async createDocument(opts:
    | { mode: 'property', formCode: SubscriptionFormCode, documentInstanceName: string, propertyId: string, propertyFormId: string, entityId: number }
    | { mode: 'legacy', formId: number, documentInstanceName: string, entityId: number, templateId?: number }
  ) {
    const newDocResult = await LegacyApi.ajax<{ documentid: string; formid: string; } & BaseAjaxResponse>(
      'createdocument',
      opts.mode === 'property'
        ? {
          FormFileName: opts.formCode,
          PropertyFolders: 'true',
          DocumentName: opts.documentInstanceName,
          PropertyID: opts.propertyId,
          PropertyFormID: opts.propertyFormId,
          EntityID: opts.entityId.toString()
        }
        : {
          FormID: opts.formId.toString(),
          DocumentName: opts.documentInstanceName,
          EntityID: opts.entityId.toString(),
          Select_TemplateID: opts.templateId?.toString()
        });

    const documentId = parseInt(newDocResult.documentid, 10);
    const formId = parseInt(newDocResult.formid, 10);
    if (isNaN(documentId) || documentId < 1) {
      console.error('Invalid response document id', newDocResult);
      throw new Error('Invalid response document id');
    }

    return {
      documentId,
      formId
    };
  }

  static async getFulfillersForDocument(formCode: SubscriptionFormCode) {
    const result = await LegacyApi.ajax<{entities: { entityId: number }[]} & BaseAjaxResponse>(
      'get_fillers', {
        FormFileName: formCode
      });

    return result.entities;
  }

  static paymentTypeToPrice(searchType: VendorSearchType): {formatted: string; cents: number} {
    switch (searchType) {
      case 'proprietor':
      case 'name':
        return { formatted: VENDOR_PRICE_STR, cents: VENDOR_PRICE_CENTS };

      case 'title':
      case 'title_pf':
      case 'address':
        return { formatted: TITLE_PRICE_STR, cents: TITLE_PRICE_CENTS };

      default:
        return { formatted: '0.00', cents: 0 };
    }
  }

  static async setDocumentAssignments(documentId: number, agentIds: number[]) {
    const formData: Record<string, string> = {
      DocumentID: documentId?.toString()
    };
    for (let i = 0; i < agentIds.length; i++) {
      formData[`agentIds[${i}]`] = agentIds[i].toString();
    }
    await LegacyApi.ajax<BaseAjaxResponse>('setassignedagents', formData);
  }

  static async getTemplates(documentId: number, formId: number | undefined, defaultSiteTemplateId: number) {
    const formData: Record<string, string> = {
      DocumentID: documentId.toString(),
      Type: defaultSiteTemplateId.toString()
    };
    if (formId) formData['FormID'] = formId.toString();

    return await LegacyApi.ajax<BaseAjaxResponse & { default: string, templates: { TemplateID: number, TemplateTitle: string, TemplateText: string }[] }>('gettemplates', formData);
  }
}

export interface ProprietorSearch {
  type: 'proprietor';
  gnaf: string;
  address?: string;
  customName?: string;
}

export interface TitleSearch {
  type: 'title_pf' | 'title';
  title: string;
  address?: string;
  customName?: string;
}

export type Searches = (ProprietorSearch | TitleSearch)[];

export interface Payment {
  type: 'title'|'mixed'|'proprietor';
  card?: Card;
  searches: Searches;
  formUrl?: string;
}

export interface Card {
  name: string;
  number: string;
  cvn: string;
  expiry_month: string;
  expiry_year: string;
  cardType: CardType;
}

export interface NoStoredCard {
  available: false,
}

export interface StoredCard {
  available: true,
  card: {
    lastFour: string;
    name: string;
    expiry: string;
    cardType: CardType;
  }
}

export interface BaseAjaxResponse {
 success: boolean
}
