import * as Y from 'yjs';
import { YClient, YClientEventName } from '@property-folders/y-client';
import { IndexeddbPersistence } from 'y-indexeddb';
import { FileRef, MaterialisedProperty, TransactionMetaData, WsMessage, YDocContentType } from '@property-folders/contract';
import { LinkBuilder } from '../util/LinkBuilder';
import { getTemplateForYDocContentType } from '@property-folders/contract/yjs-schema';
import { fromBase64 } from 'lib0/buffer';
import { PropertyStatsMain, PropertyStatsRootKey } from '@property-folders/contract/yjs-schema/property-stats';
import { Maybe } from '../types/Utility';
import { Predicate } from '../predicate';
import { getKeyFromPropertyId, materialisePropertyStats } from '../yjs-schema/property-stats';
import { PendingUpdates } from './pendingUpdates';
import { IOfflineProperty, OfflineProperties } from './offlineProperties';
import { Tasks, TaskType } from './tasks';
import { tryParseInt } from '../util';
import { EntityFileType, FileStorage, FileType, IFileRelatedData, StorageItemFileStatus } from './fileStorage';
import { FileSync } from './fileSync';
import { generateHeadlineFromMaterialisedData, materialiseProperty } from '../yjs-schema/property';
import { KeywordsBuilder } from '../util/fullText';
import { getCompletedFiles } from '../yjs-schema/property/form';
import { FileTrackDocRoot, ReuploadRequest,FileIdState, FileTrackState, jointTypes, PropertyRootKey, VendorParty, VendorPartyJoint } from '@property-folders/contract/yjs-schema/property';
import { applyMigrationsV2_1 } from '../yjs-schema';
import { materialiseEntitySettings } from '../yjs-schema/entity-settings';
import { EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { ObservableV2 } from 'lib0/observable';

interface GcOpts {
  maxProperties: number,
  gcInterval: number
}

export function getDefaultGcOpts(): GcOpts {
  return {
    maxProperties: tryParseInt(import.meta.env.VITE_YMANAGER_GC_MAX_PROPERTIES, 50),
    gcInterval: tryParseInt(import.meta.env.VITE_YMANAGER_GC_TIME_BETWEEN, 300000)
  };
}

function evaluateReupload(fileId: string, req: ReuploadRequest, fileSync: FileSync, ydoc: Y.Doc) {
  FileStorage.readMeta(fileId).then((met)=>{
    if (!(met && met.fileStatus === StorageItemFileStatus.Available)) return;
    const currentState = ydoc.getMap(PropertyRootKey.FileTrack).get('clientReup2').get(fileId).toJSON() as ReuploadRequest | undefined;
    if (!currentState) {
      // That's weird. Quit now
      return;
    }
    if (currentState.ver === currentState.claimedVer) {
      return;
    }
    // OK we should try and tell everyone else that we're doing this. Probably not super reliable,
    // but worth a shot. Should at least help with clients who come online later.

    applyMigrationsV2_1<FileTrackDocRoot>({
      typeName: 'Property',
      doc: ydoc,
      docKey: PropertyRootKey.FileTrack,
      migrations: [{
        name: 'Make signing session active again',
        fn: (draft: FileTrackDocRoot)=>{
          draft.clientReup2[fileId].claimedVer = draft.clientReup2[fileId].ver;
        }
      }]
    });
    fileSync.requeueUpload(fileId, req);
  });

}

async function fileTrackObserver (evts: Y.YEvent<any>[], fileSync: FileSync, ydoc: Y.Doc, noClientReloadResponse?: boolean) {
  for (const event of evts) {
    const pth = event.path;
    const reuploadEvaluations: {[fileId: string]: ReuploadRequest} = {};
    for (const changeNode of event.keys.entries()) {
      if (!(!noClientReloadResponse && (pth[0] === 'clientReup2' || changeNode[0] === 'clientReup2'))) continue;
      if (pth.length === 2 && typeof pth[1] === 'string' && changeNode[0] === 'ver') {
        let maybeState: any;
        try {
          maybeState = event.target.toJSON(); // Get's the object containing the change
        } catch (err) {
          continue;
        }

        if (maybeState?.ver == null || changeNode[1].oldValue === maybeState?.ver) continue;
        const rupReq = maybeState as ReuploadRequest;
        reuploadEvaluations[pth[1]] = rupReq;
      } else if (pth.length === 1 && typeof changeNode[0] === 'string') {
        let maybeState: any;
        try {
          maybeState = event.target.get(changeNode[0]).toJSON(); // Also gets the object containing the change, but we need to perform a get on it
        } catch (err) {
          continue;
        }
        const newValue = maybeState?.ver;

        if (newValue == null || changeNode[1].oldValue?.ver === newValue) continue;
        const rupReq = maybeState as ReuploadRequest;
        reuploadEvaluations[changeNode[0]] = rupReq;
      } else if (pth.length === 0) {
        let maybeState: unknown;
        try {
          maybeState = event.target.get(changeNode[0]).toJSON(); // Gets the dictionary object with all the changes
          if (!(maybeState && typeof maybeState === 'object')) {
            continue;
          }
          for (const [k,v] of Object.entries(maybeState)) {
            try {
              if (v?.ver == null || changeNode[1].oldValue?.toJSON()[k]?.ver === v?.ver) {
                continue;
              }
              const rupReq = v as ReuploadRequest;
              reuploadEvaluations[k] = rupReq;
            } catch (err) {
              console.error('Error processing key');
            }
          }
        } catch (err) {
          continue;
        }
      }
    }
    if (!noClientReloadResponse ) {
      for (const [k,v] of Object.entries(reuploadEvaluations)) {
        evaluateReupload(k, v, fileSync, ydoc);
      }
    }
  }

  let anySync = false;
  for (const event of evts) {
    const pth = event.path;

    for (const changeNode of event.keys.entries()) {
      if (!(pth[0] === 'files' || changeNode[0] === 'files')) continue;
      let fileIdState: FileIdState | null = null;
      let fileId: string | null = null;
      if (pth.length === 2 && changeNode[0] === 'state' && typeof pth[1] === 'string') {
        fileIdState = (event.target.toJSON() as FileIdState);
        fileId = pth[1];
      } else if (pth.length === 1 && typeof changeNode[0] === 'string') {
        let maybeState: unknown;
        try {
          maybeState = event.target.get(changeNode[0]).toJSON();
        } catch (err) {
          continue;
        }

        if (!(maybeState && typeof maybeState === 'object' && Object.hasOwn(maybeState,'state') && typeof maybeState.state === 'number' )) {
          continue;
        }
        fileIdState = maybeState as FileIdState;
        fileId = changeNode[0];
      } else if (pth.length === 0) {
        let maybeState: unknown;
        try {
          maybeState = event.target.get(changeNode[0]).toJSON() as {[fileId: string]: FileIdState}; // Gets the dictionary object with all the changes
          if (!(maybeState && typeof maybeState === 'object')) {
            continue;
          }
          for (const [k,v] of Object.entries(maybeState)) {
            try {
              if (v?.ver == null || changeNode[1].oldValue?.toJSON()[k]?.ver === v?.ver) {
                continue;
              }

              fileIdState = v as FileIdState;
              fileId = k;
            } catch (err) {
              console.error('Error processing key');
            }
          }
        } catch (err) {
          continue;
        }
      }

      if (!(fileIdState && fileId)) continue;
      const newValue = fileIdState.state;
      if (!(changeNode[1].oldValue !== newValue && newValue === FileTrackState.Available)) continue;

      const fileMeta = await FileStorage.readMeta(fileId);
      if (fileMeta && fileMeta.fileStatus === StorageItemFileStatus.Available) continue;
      anySync = true;
      if (fileMeta) {
        // We're only really interested if we've already asked for it before.
        FileStorage.requeueIndividualDownload(fileId);
        continue;
      }
    }
  }
  if (anySync) {
    fileSync.syncFiles();
  }
}

type ActiveEntry = ({
  type: YDocContentType.Property,
  fileTrackHandler: (evts: Y.YEvent<any>[]) => void
} | {
  type: Exclude<YDocContentType, YDocContentType.Property>;
}) & {
  id: string;
  doc: Y.Doc;
  localProvider: IndexeddbPersistence,
  handler: (update: Uint8Array, origin: unknown, doc: Y.Doc, transaction: Y.Transaction) => void;
  localOnly: boolean;
  viewing: boolean;
};

export function getVendorOrPrimarySubVendor (v: VendorPartyJoint | VendorParty) {
  const vOnly = v as VendorParty;
  if (!jointTypes.includes(v.partyType)) return vOnly;
  const vTop = v as VendorPartyJoint;
  if (!Array.isArray(vTop.namedExecutors)) return vOnly;
  let primary = vTop.namedExecutors.find(ne=>ne.id === vTop.primaryNamedExecutor);
  if (!primary) primary = vTop.namedExecutors[0];
  if (!primary) return vOnly;
  return primary;
}

function toOfflineProperty(id: string, agentId: number, property: MaterialisedProperty): IOfflineProperty {
  const { data, meta, alternativeRoots } = property;
  const primaryAddr = (data.saleAddrs || [])[0];
  const address = [
    primaryAddr?.streetAddr,
    primaryAddr?.subStateAndPost
  ]
    .map(s => s ? s.trim() : s)
    .filter(Predicate.isTruthy)
    .join(', ');
  const headline = generateHeadlineFromMaterialisedData(data);
  const vendors = (data.vendors || [])
    .filter(Predicate.isNotNull)
    .map(getVendorOrPrimarySubVendor)
    .map(v => v.fullLegalName)
    .filter(Predicate.isNotNull);
  const agents = (data.agent || []).flatMap(a => a.salesp || [])
    .map(sp => sp.name)
    .filter(Predicate.isNotNull);
  const keywordsBuilder = new KeywordsBuilder();
  // index by headline, not address, since that's what is actually shown
  keywordsBuilder.add(headline);
  keywordsBuilder.addMultiple(agents);
  keywordsBuilder.addMultiple(vendors);

  return {
    id,
    agentId,
    keywords: keywordsBuilder.keywords(),
    headline,
    address,
    vendors,
    agents,
    meta,
    sublineageMeta: Object.assign({}, ...Object.entries(alternativeRoots??{}).map(([k,v])=>({ [k]: v.meta })))
  };
}

const gcLockKey = 'YManagerGarbageCollecting';

export type YManagerUser =
  | { agentId: number, agentUuid?: string }
  | { portalUserId: string };
export interface YManagerEvents {
  deniedIds: (ids: string[]) => void;
}

export class YManager extends ObservableV2<YManagerEvents> {
  private static _instance?: YManager = undefined;
  private static _ro = new Set<string>();

  public static instance({
    user,
    offlineEnabled,
    fileSync,
    onDisconnect,
    websocketEndpoint,
    impersonator
  }: {
    user?: YManagerUser
    offlineEnabled?: boolean,
    fileSync?: FileSync,
    onDisconnect?: () => void,
    websocketEndpoint?: string,
    impersonator?: boolean
  } = {}) {
    if (!this._instance) {
      if (!(user && impersonator != null)) {
        throw new Error('Cannot construct ymanager, agent id not specified or session missing');
      }
      const client = YClient.instance(websocketEndpoint || LinkBuilder.websocketEndpoint, {
        isRo: (id) => this._ro.has(id)
      });

      if (onDisconnect) {
        client.on(YClientEventName.disconnect, () => {
          onDisconnect();
        });
      }
      this._instance = new YManager(client, user, offlineEnabled
        ? undefined
        : {
          maxProperties: 0
        }, fileSync, impersonator);

    }
    return this._instance;
  }

  public static unbind() {
    if (!this._instance) {
      return;
    }

    this._instance.destroy();
    this._instance = undefined;
  }

  public static async hasPendingOutboundMessages(agentId: Maybe<number>) {
    return this._instance?.client.hasPendingOutboundMessages() || await PendingUpdates.hasPending(agentId);
  }

  private active: Map<string, ActiveEntry> = new Map();
  private gcOpts: GcOpts;
  private onPendingUpdateHandler: (doc: Y.Doc, pendingUpdate: boolean, id: string) => Promise<void>;
  private onMessageHandler: (message: WsMessage) => void;
  private onDeniedHandler: (ids: string[]) => void;
  private onHasAccessHandler: (id: string) => void;
  /**
   * This is meant to be a soft indicator of what the user shouldn't see.
   * e.g. if they visit a deep link and are denied, then we can present something to them on-screen,
   * but should still attempt to request an update from the server if revisited, because permissions can change.
   */
  private deniedIdCache  = new Set<string>();

  public get gcInterval() {
    return this.gcOpts.gcInterval;
  }

  public get maxProperties() {
    return this.gcOpts.maxProperties;
  }

  constructor(
    private client: YClient,
    private user: YManagerUser,
    gcOpts?: Partial<GcOpts>,
    private fileSync: FileSync | undefined,
    private impersonator: boolean
  ) {
    super();
    this.gcOpts = gcOpts
      ? { ...getDefaultGcOpts(), ...gcOpts }
      : getDefaultGcOpts();
    this.getUserPrefs();
    this.bindTrackedProperties()
      .catch(err => console.error('YManager - failed to bind all offline docs', err));

    this.onPendingUpdateHandler = async (doc: Y.Doc, pendingUpdate: boolean, id: string) => {
      if (!('agentId' in user)) return;
      if (pendingUpdate) {
        await PendingUpdates.setPending(id, user.agentId);
        return;
      }

      await PendingUpdates.unsetPending(id, user.agentId);
    };
    this.onMessageHandler = (message: WsMessage) => {
      switch (message.type) {
        case 'property-access':
          this.get(message.propertyId, YDocContentType.Property, {});
          return;
      }
    };
    this.onDeniedHandler = (ids: string[]) => {
      for (const id of ids) {
        this.deniedIdCache.add(id);
        this.destroyEntry(id)
          .catch(err => console.error(`failed to drop denied entry ${id}`, err));
      }
      if (ids.length) {
        this.emit('deniedIds', [[...this.deniedIdCache.values()]]);
      }
    };
    this.onHasAccessHandler = (id: string) =>{
      this.deniedIdCache.delete(id);
      this.emit('deniedIds', [[...this.deniedIdCache.values()]]);
    };

    this.client.on(YClientEventName.pendingUpdate, this.onPendingUpdateHandler);
    this.client.on(YClientEventName.message, this.onMessageHandler);
    this.client.on(YClientEventName.denied, this.onDeniedHandler);
    this.client.on(YClientEventName.hasAccess, this.onHasAccessHandler);
  }

  private async bindTrackedProperties() {
    if (!('agentId' in this.user)) return 0;

    const properties = await OfflineProperties.all(this.user.agentId);
    for (const property of properties) {
      this.get(property.id, YDocContentType.Property, {});
      this.get(getKeyFromPropertyId(property.id), YDocContentType.PropertyStats, {});
    }
    return properties.length;
  }

  public getUserPrefs() {
    if ('agentUuid' in this.user && this.user.agentUuid) {
      return this.get(this.user.agentUuid, YDocContentType.UserPreferences, {
        preferLocal: false
      });
    }

    if ('agentId' in this.user) {
      return this.get(`user-prefs_${this.user.agentId}`, YDocContentType.UserPreferences, {
        preferLocal: true
      });
    }

    return undefined;
  }

  /**
   * Get ydoc
   * If it doesn't yet exist, then open/bind a new one
   * todo: (future) change preferLocal to preferredNetworkMode: 'online' | 'offline'
   * todo: (future) perhaps an additional param to allow/disallow upgrades?
   * todo: (future) extract out per-doc-type handling elsewhere
   */
  public get(id: string, type: YDocContentType, opts: {
    preferLocal?: boolean,
    noCreate?: boolean,
    forceLoad?: boolean,
    readOnly?: boolean
  }): ActiveEntry {
    if (opts.readOnly) {
      YManager._ro.add(id);
    } else {
      YManager._ro.delete(id);
    }
    const existing = opts.forceLoad ? undefined : this.active.get(id);

    if (existing) {
      if (existing.localOnly && !opts.preferLocal) {
        console.log('upgrade existing local-only ydoc to network-connected', id);
        this.client.bind(existing.doc, id, type);
        existing.localOnly = false;
      }
      return existing;
    }

    const doc = new Y.Doc();
    if (!opts.noCreate) {
      const template = getTemplateForYDocContentType(type);
      if (template) {
        Y.applyUpdate(doc, fromBase64(template));
      }
    }

    const handler = (update: Uint8Array, origin: unknown, doc: Y.Doc, transaction: Y.Transaction) => {
      this.updateHandler(id, doc, !transaction.local).catch(console.error);
    };

    doc.on('update', handler);
    const fileTrackHandler = (evts: Y.YEvent<any>[]) => {

      if (!this.fileSync) {
        // Nothing we can do
        return;
      }
      fileTrackObserver(evts, this.fileSync, doc, this.impersonator).catch(err => console.error('Error operating file track', err));
    };

    const localProvider = new IndexeddbPersistence(id, doc);

    if (!opts.preferLocal) {
      this.client.bind(doc, id, type);
    }
    if (type === YDocContentType.Property) {
      localProvider.whenSynced.then(()=>{
        doc.getMap(PropertyRootKey.FileTrack).observeDeep(fileTrackHandler);
      });
    }

    const entry: ActiveEntry = {
      id,
      ...(type === YDocContentType.Property ? {
        type: YDocContentType.Property,
        fileTrackHandler: fileTrackHandler
      } : {
        type
      }),
      doc,
      handler,
      localProvider,
      localOnly: opts.preferLocal || false,
      viewing: false
    };
    this.active.set(id, entry);
    return entry;
  }

  public bindAwareness(doc: Y.Doc) {
    return this.client.bindAwareness(doc);
  }

  public unbindAwareness(doc: Y.Doc) {
    return this.client.unbindAwareness(doc);
  }

  /**
   *
   * @param meta Expects metadata from the main root key, not a sublineage
   * @param propertyId
   * @returns
   */
  private async ingestMissingPropertyFiles(materialised: MaterialisedProperty, propertyId: string) {
    let newFilesQueued = false;
    const queueFileBase = async (file: Maybe<FileRef>, relatedData: IFileRelatedData) => {
      if (!file) {
        return false;
      }
      const queued = await FileStorage.queueDownload(file.id, FileType.PropertyFile, file.contentType, relatedData);
      newFilesQueued = newFilesQueued || queued;
      return true;
    };

    for (const cachedParty of materialised.meta.partyCache || []) {
      await queueFileBase(cachedParty.images.initials, { propertyId, cachedPartyImage: { propertyId, canonicalId: cachedParty.canonicalId } });
      await queueFileBase(cachedParty.images.signature, { propertyId, cachedPartyImage: { propertyId, canonicalId: cachedParty.canonicalId } });
    }

    for (const pair of [materialised, ...(Object.values(materialised.alternativeRoots??{}))]) {
      const meta = pair.meta;
      if (!meta.formStates) continue;
      for (const key of Object.keys(meta.formStates)) {
        const formState = meta.formStates[key];

        //fetch served PDF's
        for (const r of formState?.recipients||[]) {
          await queueFileBase(r.manuallyServed?.signedCopy, { propertyId });
        }

        for (const instance of formState.instances || []) {
          const session = instance.signing?.session;

          const queueFile = async (file: Maybe<FileRef>) => {
            return queueFileBase(file, {
              propertyId,
              propertyFile: {
                propertyId,
                formId: instance.id,
                formCode: instance.formCode,
                signingSessionId: session?.id
              }
            });
          };
          const queueAllFiles = async (files: Maybe<FileRef>[]) => {
            for (const file of files?.filter(Boolean)??[]) {
              await queueFile(file);
            }
          };

          // fetch uploaded document
          instance.upload && await queueFile(instance.upload);

          // fetch annexures
          instance.annexures && await queueAllFiles(instance.annexures as FileRef[]);

          //released form1 previews
          instance.order?.pdfPreview && await queueFile(instance.order.pdfPreview);

          // fetch signing stuff if theres a signing session
          if (!session) {
            continue;
          }

          // fetch all completedFiles if there are any, otherwise get the latest intermediate, or original file
          if (getCompletedFiles(session).length) {

            await queueAllFiles(getCompletedFiles(session));
          } else {
            await queueAllFiles([
              session.intermediateFiles?.slice(-1)[0],
              session.file
            ]);
          }

          // Fetch other data, such as property snapshot
          await queueAllFiles(Object.values(session?.associatedFiles ?? {}));

          // fetch all signatures
          await queueAllFiles(session.fields?.map(f => f.file));
        }
      }
    }

    if (newFilesQueued && this.fileSync) {
      this.fileSync.syncFiles().catch(console.error);
    }
  }

  private async updateHandler(id: string, doc: Y.Doc, ingestFiles: boolean) {
    const activeEntry = this.active.get(id);
    if (!activeEntry) {
      return;
    }
    switch (activeEntry.type) {
      case YDocContentType.Property: {
        const materialised = materialiseProperty(doc);
        if (!materialised || !materialised.data.id) {
          console.error('Invalid property');
          return;
        }

        try {
          if ('agentId' in this.user) {
            await OfflineProperties.write(id, this.user.agentId, toOfflineProperty(id, this.user.agentId, materialised));
          }

          if (ingestFiles) {
            await this.ingestMissingPropertyFiles(materialised, materialised.data.id);
          }
        } catch (err: unknown) {
          console.error(err);
        }
        return;
      }
      case YDocContentType.PropertyStats: {
        if (!('agentId' in this.user)) {
          return;
        }
        const agentId = this.user.agentId;
        const main = doc.getMap(PropertyStatsRootKey.Main).toJSON() as PropertyStatsMain;
        const agentLastAccessedItems = main.userAccessTimes.filter(i => i.id === agentId);
        if (agentLastAccessedItems.length === 0) {
          return;
        }

        const agentLastAccessed = Math.max(...agentLastAccessedItems.map(i => i.ts));
        if (agentLastAccessed) {
          await OfflineProperties.setLastAccessed(main.id, agentId, agentLastAccessed);
        }
        return;
      }
      case YDocContentType.EntitySettings: {
        if (!('agentId' in this.user)) {
          return;
        }
        const materialised = materialiseEntitySettings(doc);
        materialised && await this.ingestEntityFiles(materialised);
        return;
      }
      default:
        return;
    }
  }

  private async ingestEntityFiles(entitySettings: EntitySettingsEntity) {
    //marketing template header images
    for (const template of entitySettings.marketingTemplates||[]) {
      template.headerImage && await FileStorage.queueDownload(template.headerImage.id, FileType.EntityFile, template.headerImage.contentType, {
        entityFile: {
          fileType: EntityFileType.MarketingTemplate,
          entityUuid: template.entityUuid,
          parentId: template.id
        }
      });
    }
  }

  public async waitForSync(doc: Y.Doc) {
    return await this.client.waitForSync(doc);
  }

  private unbindEntryInternal(entry: ActiveEntry) {
    this.client.unbind(entry.doc);
  }

  /**
   * Delete and stop tracking an entry.
   * This will unbind and remove from storage.
   * @param id
   */
  public async destroyEntry(id: string) {
    const entry = this.active.get(id);
    if (!entry) {
      return;
    }

    // note: don't avoid deleting if it's entity settings.
    // the client shouldn't broadcast entity settings changes up to the server,
    // so if it does then it deserves to be punished!

    if (entry.type === YDocContentType.Property && entry.fileTrackHandler) {
      entry.doc.getMap(PropertyRootKey.FileTrack).unobserveDeep(entry.fileTrackHandler);
    }

    this.unbindEntryInternal(entry);
    await entry.localProvider.clearData();
    await entry.localProvider.destroy();
    if (entry.type === YDocContentType.Property) {
      if ('agentId' in this.user) {
        await OfflineProperties.delete(id, this.user.agentId);
      }
      await this.destroyEntry(getKeyFromPropertyId(id));
    }
    this.active.delete(id);
  }

  /**
   * Unbind entries from network updates.
   * Keep them stored locally though!
   */
  public destroy() {
    this.client.off(YClientEventName.pendingUpdate, this.onPendingUpdateHandler);
    this.client.off(YClientEventName.message, this.onMessageHandler);
    this.client.off(YClientEventName.denied, this.onDeniedHandler);

    const entries = [...this.active.values()];
    for (const entry of entries) {
      this.unbindEntryInternal(entry);
    }
    this.active.clear();

    this.client.destroy();
  }

  private async garbageCollectInner () {
    const activeProperties = [...this.active.values()]
      .filter(x => x.type === YDocContentType.Property)
      .map(x => [x, !!((x.doc.getMap(PropertyRootKey.Meta).toJSON() as TransactionMetaData).archived)] as [activeProperty: typeof x, archived: boolean]);

    const toDelete = activeProperties
      .filter(([_, archived]) => archived)
      .map(([x])=>({ id: x.id, ts: 0 }));

    const candidatePropertyIds = activeProperties
      .filter(([_, archived]) => !archived)
      .map(([x]) => x.id);

    const expectedDeletionCount = candidatePropertyIds.length - this.gcOpts.maxProperties;
    if (expectedDeletionCount > 0) {
    // we need to order them by last access time
      const accessTimes: Array<{id: string, ts: number}> = [];
      for (const id of candidatePropertyIds) {
        const stats = materialisePropertyStats(this.get(getKeyFromPropertyId(id), YDocContentType.PropertyStats, {}).doc);
        if (!stats) {
          accessTimes.push({ id, ts: 0 });
          continue;
        }
        if ('agentId' in this.user) {
          const agentId = this.user.agentId;
          const atMax = Math.max(...(stats.userAccessTimes.filter(x => x.id === agentId).map(x => x.ts)));
          if (atMax !== Number.NEGATIVE_INFINITY) {
            accessTimes.push({ id, ts: atMax });
            continue;
          }
        }
        accessTimes.push({ id, ts: 0 });

      }
      const excessDeletion = accessTimes.sort((a, b) => a.ts - b.ts).slice(0, expectedDeletionCount);
      toDelete.splice(0,0,...excessDeletion);
    }
    if (toDelete.length === 0) {
      // Early quit, even though not having this here should in theory make no difference,
      // this makes clear there are no further actions in this function
      return;
    }

    for (const item of toDelete) {
      if (this.active.get(item.id)?.viewing) {
        console.warn(`[gc] cannot gc ${item.id} as it is currently being viewed`);
        continue;
      }
      if (this.client.hasPendingOutboundMessagesFor(item.id)) {
        console.warn(`[gc] cannot gc ${item.id} as it has pending outbound messages`);
        continue;
      }

      try {
        await this.destroyEntry(item.id);
      } catch (err: unknown) {
        console.error('[gc] failed to destry entity', err);
      }
    }
    console.log(`[gc] garbage-collected ${toDelete.length} properties`);
  }

  public async garbageCollect() {
    if (localStorage.getItem(gcLockKey)) {
      console.warn('[gc] another ymanager garbage collection is already underway');
      return;
    }
    localStorage.setItem(gcLockKey, 'true');

    try {
      const lastExecution = await Tasks.getLastExecution(TaskType.YManagerGarbageCollect) || 0;
      if (lastExecution > (Date.now() - this.gcOpts.gcInterval)) {
        return;
      }

      try {
        await this.garbageCollectInner();
      } catch (err: unknown) {
        console.error('[gc] Error while garbage collecting properties', err);
      } finally {
        await Tasks.setLastExecution(TaskType.YManagerGarbageCollect, Date.now());
      }
    } finally {
      localStorage.removeItem(gcLockKey);
    }
  }

  public static clearLock() {
    localStorage.removeItem(gcLockKey);
  }

  public printGc() {
    console.log('[gc] settings', this.gcOpts);
  }

  markUserViewing(id: string, viewing: boolean) {
    const entry = this.active.get(id);

    if (!entry) {
      return;
    }

    entry.viewing = viewing;
  }
}
