import { z } from 'zod';

export const LookupPropertiesQueryParamsSchema = z.object({
  searchTerm: z.optional(z.string()),
  formCode: z.optional(z.string()),
  sublineageIntent: z.optional(z.string().transform(x => x === 'true')),
  limit: z.optional(z.coerce.number().min(1).max(10))
});
export type LookupPropertiesQueryParams = z.infer<typeof LookupPropertiesQueryParamsSchema>;

export interface LookupPropertiesMatchingForm {
  formId: string;
  formCode: string;
  timestamp: number;
  subscription?: boolean;
  sublineageId?: string;
}

export interface LookupPropertiesResultItem {
  propertyId: string;
  created: number;
  headline: string;
  saleAddresses: string[];
  vendors: string[];
  // canAddForm?: boolean;
  // matchingForms?: LookupPropertiesMatchingForm[];
  addRestriction?:
    | { type: 'existing', formId: string, formCode: string }
    | { type: 'unsigned', formId: string, formCode: string }
    | { type: 'invalid' };
}

export interface LookupPropertiesResult {
  items: LookupPropertiesResultItem[];
}

export const LookupEntitiesQueryParamsSchema = z.union([
  z.object({
    formId: z.coerce.number()
  }),
  z.object({
    canManage: z.string().transform(s => Boolean(s && s.toLowerCase() === 'true'))
  })
]);
export type LookupEntitiesQueryParams = z.infer<typeof LookupEntitiesQueryParamsSchema>;

export interface LookupEntitiesResultItem {
  entityId: number;
  entityUuid: string;
  name: string;
  tradingName?: string;
  rla?: string;
  abn?: string;
  profileName?: string;
  parentId?: number
  parentName?: string
}

export interface LookupEntitiesResult {
  items: LookupEntitiesResultItem[];
}

export const LookupClassicTemplatesQueryParamsSchema = z.object({
  formId: z.coerce.number(),
  entityId: z.coerce.number()
});
export type LookupClassicTemplatesQueryParams = z.infer<typeof LookupClassicTemplatesQueryParamsSchema>;

export interface LookupClassicTemplatesResultItem {
  templateId: number;
  name: string;
}

export interface LookupClassicTemplatesResult {
  items: LookupClassicTemplatesResultItem[]
}

export const LookupClassicFormsQueryParamsSchema = z.object({
  formId: z.optional(z.coerce.number()),
  formFileName: z.optional(z.string())
});
export type LookupClassicFormsQueryParams = z.infer<typeof LookupClassicFormsQueryParamsSchema>;

export interface LookupClassicFormsResultItem  {
  formId: number;
  formFileName: string;
  name: string;
}

export interface LookupClassicFormsResult {
  items: LookupClassicFormsResultItem[];
}

export const LookupContactsQueryParamsSchema = z.object({
  subscriptionDocumentId: z.optional(z.coerce.number()),
  propertyId: z.optional(z.string())
});
export type LookupContactsQueryParams = z.infer<typeof LookupContactsQueryParamsSchema>;

export type ContactType =
  | 'agent'
  | 'vendor'
  | 'purchaser'
  | 'landlord'
  | 'tenant'
  | 'recipient'
  | 'seller';

export interface LookupContactsResultItem {
  address: string;
  name?: string;
  type?: ContactType
}

export interface LookupContactsResult {
  items: LookupContactsResultItem[];
}

export interface LookupCanShareResultItem {
  id: number;
  sid: number;
  type: 'agent' | 'team' | 'entity';
  name: string;
  subtitle: string;
}

export interface LookupCanShareResult {
  items: LookupCanShareResultItem[];
}
