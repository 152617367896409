import {
  Annexure,
  FormCode,
  FormInstance,
  FormStates,
  InstanceHistory,
  MaterialisedPropertyData,
  PurchaserParty,
  SigningParty,
  VendorParty,
  jointTypes
} from '@property-folders/contract';
import { cloneDeep, find, findIndex } from 'lodash';
import { normaliseToDayStart } from './formatting/functions/normaliseToDayStart';
import { parseInt2 } from './formatting/functions/parseInt2';
import { FormTypes } from '../yjs-schema/property/form';
import { getValueByPath } from './pathHandling';
import { PathType } from '@property-folders/contract/yjs-schema/model';
import { formCompleted } from './form/formCompleted';
import { Predicate } from '../predicate';
import { jointPartyConfig, partyFieldConfig } from './formatting/constants';
import { NIL, v5 } from 'uuid';
import { Snapshot } from 'immer-yjs/src';
import { DataGeneration, DetermineReplacedRemovedLockedResult, IDObject, IDObjectDefault } from './dataExtractTypes';

export function getPrimaryParty(partyList: (VendorParty|PurchaserParty)[] | undefined, primaryId: string | undefined) {
  if (primaryId) {
    return find(partyList, party=>party?.id === primaryId);
  }
  if (Array.isArray(partyList) && partyList.length > 0) {
    return partyList[0];
  }
  return undefined;
}

export function getDataModelPrimaryContactDetails(purchaserList?: PurchaserParty[], primaryPurchaserId?: string) {

  const primaryPurchaser = Array.isArray(purchaserList) && primaryPurchaserId
    ? purchaserList.find(p => p.id === primaryPurchaserId)
    : undefined;

  const { partyType, authority } = (primaryPurchaser || {}) as {partyType?: string, authority?: string};
  const configuration = jointTypes.includes(partyType) ? jointPartyConfig[partyType] : (partyType && authority && partyFieldConfig[partyType][authority]);

  const {
    positionContact1,
    positionContact2
  } = configuration || {};
  const dualContact = positionContact1 && positionContact2;
  const subPrimary = dualContact && primaryPurchaser?.primarySubcontact === 1
    ? { email: primaryPurchaser.email2, phone: primaryPurchaser.phone2 }
    : { email: primaryPurchaser?.email1, phone: primaryPurchaser?.phone1 };
  return { subPrimary, dualContact, primaryPurchaser };
}

export function blobTob64(file?: Blob, ignoreErrors = false): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!file && ignoreErrors) {
      resolve('');
      return;
    } else if (!file) {
      reject('No blob provided');
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const { result } = reader;
      if (typeof result !== 'string') {
        reject('Blob has not resolved to a string');
        return;
      }
      resolve(result);
    };
    reader.readAsDataURL(file);
  });
}

export const noExpiryValue = new Date('9999-12-31');

type expiryDescriptor = {
  id: string
  thisInstance: FormInstance
  startDate: Date
  expiryDate: Date | null
};

export function getExpiryDescriptorTimeRanges(sets: expiryDescriptor[][]) {
  return sets.map(set => {
    if (set.length === 0) return null;
    const desc = set[set.length-1];

    const start = desc.startDate;
    const end = desc.expiryDate;
    if (!start || !end) return null;
    return { start, end, inst: desc.thisInstance, instFile: desc.thisInstance.signing?.session?.associatedFiles?.propertyDataSnapshot?.id };
  });
}

export function determineStartAndDurationForFile(
  instances: FormInstance[] | undefined, data: MaterialisedPropertyData, inst: FormInstance | undefined
) {
  if (!instances || !inst) return null;
  let agreementTime = inst?.signing?.session?.completedTime;
  if (FormTypes[inst.formCode].isVariation) {
    const position = instances.findIndex(i=>i.id === inst.id);
    const previousIndex = instances
      .map(i=>!FormTypes[i.formCode].isVariation)
      .reduce((pv, cv, ci)=>{
        return ci >= position
          ? pv // If instance after current instance, we've either already found it or we're in error
          : cv
            ? ci // Current form is not a variation, this might be the base of this variation, but check the next one just in case
            : pv; // Current form is a variation, ignore it
      }, -1);
    if (previousIndex === -1) {
      return null;
    }
    agreementTime = instances[previousIndex].signing?.session?.completedTime;
  }
  if (!agreementTime) {
    return null;
  }

  const durationPath = FormTypes[inst.formCode]?.documentExpiryPaths?.duration;
  const startDatePath = FormTypes[inst.formCode]?.documentExpiryPaths?.startDate;

  // As per AgencyAgreementTerms['start'], true or undefined shall mean on date of agreement
  const startEnablePath = FormTypes[inst.formCode]?.documentExpiryPaths?.startEnable;
  const startDate = startDatePath
    ? getValueByPath(startDatePath, data, true)
    : undefined;
  // A start date being present may be enough to act as an enable, if no separate enable flag exists
  const startEnable = startEnablePath
    ? getValueByPath(startEnablePath, data, true)
    : !!startDate;
  const agencyLength = durationPath ?
    getValueByPath(durationPath, data, true)
    ?? FormTypes[inst.formCode]?.documentExpiryPathsDefaults?.duration
    : undefined;
  const agencyStartDocument = (startEnable === false && startDate)
    ? normaliseToDayStart(new Date(startDate))
    : null;
  const agencyStartAgreement = normaliseToDayStart(
    new Date(agreementTime)
  );

  const thisStart = normaliseToDayStart(agencyStartDocument ?? agencyStartAgreement);
  return { start: thisStart, duration: agencyLength };
}

export function buildAllSigningTimelines(snapshotHistory?: Partial<InstanceHistory>): expiryDescriptor[][] | null {
  if (!snapshotHistory?.data || !snapshotHistory?.instanceList) {
    return null;
  }
  const sortedInstances = snapshotHistory.instanceList.slice().sort((instanceA, instanceB) => {
    // ascending sort
    return (instanceA.signing?.session?.completedTime||0) - (instanceB.signing?.session?.completedTime||0);
  });

  const expirations: expiryDescriptor[][] = [];
  let currentSet: expiryDescriptor[] = [];
  let latestStart: Date | null  = null;
  let latestExpiry: Date | null  = null;

  const crystaliseEndOfSeries = () => {
    expirations.push(currentSet);
    currentSet = [];
    latestStart = null;
    latestExpiry = null;
  };

  for (const inst of sortedInstances) {
    const fileId = inst?.signing?.session?.associatedFiles?.propertyDataSnapshot?.id;
    if (!fileId || !inst.signing?.session?.completedTime) {
      continue;
    }
    const snapshottedData = snapshotHistory.data[fileId] as MaterialisedPropertyData | undefined;
    if (!snapshottedData) {
      continue;
    }

    const durationPath = FormTypes[inst.formCode]?.documentExpiryPaths?.duration;
    const startDatePath = FormTypes[inst.formCode]?.documentExpiryPaths?.startDate;

    // As per AgencyAgreementTerms['start'], true or undefined shall mean on date of agreement
    const startEnablePath = FormTypes[inst.formCode]?.documentExpiryPaths?.startEnable;
    const startDate = startDatePath ? getValueByPath(startDatePath, snapshottedData, true) : undefined;
    const startEnable = startEnablePath ? getValueByPath(startEnablePath, snapshottedData, true) : !!startDate; // A start date being present may be enough to act as an enable, if no separate enable flag exists
    const agencyLength = durationPath ? getValueByPath(durationPath, snapshottedData, true) ?? FormTypes[inst.formCode]?.documentExpiryPathsDefaults?.duration : undefined;
    const agencyStartDocument = (startEnable === false && startDate) ? normaliseToDayStart(new Date(startDate)) : null;
    const agencyStartAgreement = normaliseToDayStart(new Date(inst.signing?.session?.completedTime || 0));

    let thisStart = normaliseToDayStart(agencyStartDocument ?? agencyStartAgreement);

    // Expiry is exclusive in this code, it isn't the legal definition of expiry. Think
    // [start, expiry). As such, normalised to day start may have the same date as another end, but
    // they do not "overlap", they are just joined by an infinitely small/zero gap
    if (latestExpiry && latestExpiry.getTime() <= thisStart.getTime()) {
      crystaliseEndOfSeries();
      latestStart = null;
      latestExpiry = null;
    }

    if (!durationPath) {
      latestExpiry = noExpiryValue;
    } else if (!latestStart) {
      /*
      const secondsSinceDayStart = expiryDate.getHours()*3600 + expiryDate.getMinutes()*60 + expiryDate.getSeconds();
      // we'll say anything before 7am local time means the whole day is included, not excluded
      const clearDayAdder = secondsSinceDayStart < 7*3600 ? 0 : 1;

      There are no "clear days" for times represented as beginning on a day as per the act, and the
      text in the agreement states "The agreement begins on:". Further, clear days may result in an
      agency of effectively more than 90 days, which is strictly prohibited.

      The code snippet above is kept as a representation of what a mechanism to do this might have
      looked like
      */
      latestStart = thisStart;
      const expiryInplace = new Date(thisStart);

      expiryInplace.setDate(thisStart.getDate()+(parseInt2(agencyLength)??0));
      latestExpiry = normaliseToDayStart(expiryInplace);
    } else {
      // Overwrite thisStart because this is (probably) a variation
      thisStart = normaliseToDayStart(agencyStartDocument ?? latestStart);
      const expiryInplace = new Date(thisStart ? thisStart : new Date(0));
      expiryInplace.setDate(thisStart.getDate()+(parseInt2(agencyLength)??0));
      if (expiryInplace > (latestExpiry === noExpiryValue ? 0 : (latestExpiry||0))) {
        // In the case of an extended expiry, because the original was less than 90 days
        latestExpiry = normaliseToDayStart(expiryInplace);

      }
    }
    currentSet.push({
      startDate: latestStart,
      expiryDate: latestExpiry,
      id: inst.id,
      thisInstance: inst
    });
  }
  crystaliseEndOfSeries();
  return expirations;
}

export interface SigningTimeline {
  instanceSets: FormInstance[][],
  latestExpiry: Date | false,
  lastValidDay: Date | false,
  latestStart: Date | null,
  latestSignedId: string | null,
  latestSignedFileId: string | null
}

function getEmptySigningTimeline(): SigningTimeline {
  return {
    instanceSets: [],
    latestExpiry: false,
    latestSignedId: null,
    latestSignedFileId: null,
    lastValidDay: false,
    latestStart: null
  };
}

export function buildSigningTimelines (snapshotHistory?: Partial<InstanceHistory>): SigningTimeline {
  const res = buildAllSigningTimelines(snapshotHistory);
  if (!res?.length) return getEmptySigningTimeline();

  const lastSet = res.at(-1);
  if (!lastSet) return getEmptySigningTimeline();

  const lastEntry = lastSet.at(-1);
  if (!lastEntry) return getEmptySigningTimeline();

  const lastValidDay = lastEntry.expiryDate ? new Date(lastEntry.expiryDate) : false;
  if (lastValidDay) {
    lastValidDay.setDate(lastValidDay.getDate()+1);
  }
  return {
    instanceSets: res?.map(desc => desc.map(d=>d.thisInstance)),
    latestExpiry: lastEntry.expiryDate ?? false,
    latestStart: lastEntry.startDate,
    latestSignedId: lastEntry.thisInstance.id,
    latestSignedFileId: lastEntry.thisInstance.signing?.session?.associatedFiles?.propertyDataSnapshot?.id ?? null,
    lastValidDay
  };
}

export type ListHistoryFn<TListItem extends IDObject = IDObjectDefault> = (inst: FormInstance, history: InstanceHistory, listPath: PathType, idDataList: string[], dataList: TListItem[]) => void;

function processDataList(inst: FormInstance, history: InstanceHistory, listPath: PathType, idDataList: string[], dataList: IDObjectDefault[]) {
  const snapshotFileId = inst.signing?.session?.associatedFiles?.propertyDataSnapshot?.id;
  if (!snapshotFileId) {
    console.warn('snapshot id not found!');
    return;
  }
  const data = history.data[snapshotFileId];
  if (!data) {
    console.warn('snapshot data not found!');
    return;
  }
  const list = getValueByPath(listPath, data, true);
  if (!Array.isArray(list)) {
    return;
  }
  for (const item of list) {
    const testId = item?.id;
    if (!testId) {
      console.warn('list entry has no ID!');
      continue;
    }
    const existingIndex = idDataList.indexOf(testId);
    if (existingIndex === -1) {
      idDataList.push(testId);
      dataList.push(item);
    } else {
      dataList[existingIndex] = item;
    }
  }
}

export function processAnnexureHistory(inst: FormInstance, history: InstanceHistory, listPath: PathType, idDataList: string[], dataList: Annexure[]) {
  const list = inst?.annexures;
  if (!Array.isArray(list)) {
    return;
  }
  for (const item of list) {
    const testId = item?.id;
    if (!testId) {
      console.warn('list entry has no ID!');
      continue;
    }
    const existingIndex = idDataList.indexOf(testId);

    if (existingIndex === -1) {
      idDataList.push(testId);
      dataList.push(item);
    } else if (item._removedMarker) {
      dataList[existingIndex] = cloneDeep(dataList[existingIndex]);
      dataList[existingIndex]._removedMarker = true;
      if (item._replacedBy) dataList[existingIndex]._replacedBy = item._replacedBy;
    } else {
      dataList[existingIndex] = item;
    }
  }
}

export function buildListHistory<TListItem extends IDObject = IDObjectDefault>(
  listPath: PathType,
  history: InstanceHistory,
  latestExpiry: Date | false | undefined,
  instanceProcessor: ListHistoryFn<TListItem> = processDataList
): TListItem[] {
  if (latestExpiry && latestExpiry < new Date()) {
    return [];
  }

  const orderedInstanceSets = history.signingTimelines;
  if (!Array.isArray(orderedInstanceSets) || orderedInstanceSets.flat().length === 0) {
    return [];
  }
  const latestInstanceSet = orderedInstanceSets[orderedInstanceSets.length-1];
  const idDataList: string[] = [];
  const dataList: TListItem[] = [];
  for (const inst of latestInstanceSet) {
    instanceProcessor(inst, history, listPath, idDataList, dataList);
  }
  return dataList;
}

/**
 *
 * @param listPath An empty path does not neccessarily mean transaction root, it could also signify
 *  that currentData is actually the list of interest
 * @param previousOrdering
 * @param currentData
 * @returns
 */
export function determineReplacedRemovedLocked<TListItem extends IDObject = IDObjectDefault>(
  listPath: PathType,
  previousOrdering: TListItem[],
  currentData: MaterialisedPropertyData | TListItem[],
  initialState = DataGeneration.Removed
): DetermineReplacedRemovedLockedResult<TListItem>[] {
  const currentDataList = (getValueByPath(listPath, currentData, true) ?? []) as TListItem[]; // Remember no path may mean currentData is the list of interest
  // @ts-ignore complains about oldData but the way this func is written, it'll be set below.
  const result = previousOrdering.map<DetermineReplacedRemovedLockedResult<TListItem>>(o => ({
    id: o.id,
    state: o?._restoredMarker
      ? DataGeneration.Restored
      : o?._removedMarker
        ? DataGeneration.Removed
        : initialState,
    data: o
  }));

  for (const item of currentDataList) {
    if (!item.id) {
      console.warn('No id on list item');
      continue;
    }
    const matchedIdx = findIndex(result, i=>i.id === item.id);
    if (matchedIdx === -1) {
      result.push({ id: item.id, state: DataGeneration.Added, data: item });
    } else if (item._restoredMarker) {
      result[matchedIdx].state = DataGeneration.Restored;
      delete result[matchedIdx].data?._removedMarker;
    } else if (item._removedMarker) {
      result[matchedIdx].data = cloneDeep(result[matchedIdx].data);
      result[matchedIdx].state = DataGeneration.Removed;
      result[matchedIdx].data = { ...result[matchedIdx].data, _removedMarker: true };
    } else {
      result[matchedIdx].state = DataGeneration.CarriedOver;
      if (result[matchedIdx].data?._restoredMarker) {
        result[matchedIdx].data = cloneDeep(result[matchedIdx].data);
        delete result[matchedIdx].data?._removedMarker;
        result[matchedIdx].state = DataGeneration.Restored;
      } else {
        // @ts-ignore
        result[matchedIdx].oldData = result[matchedIdx].data;
        result[matchedIdx].data = item;
      }
    }
  }

  return result;
}

const generalListMemberPathSegmentRe = /\[([\w0-9-]+)\]/i;
export function extractPathIndexText(pathSegment: string) {
  const matches = generalListMemberPathSegmentRe.exec(pathSegment);
  const result = matches?.[1];

  return result;
}

function isStrInt(testStr: string) {
  const num = Number.parseInt(testStr);
  return `${num}` === testStr;
}

export function getArrayMemberId(pathSegment: string, parentNode: ({id: string}|undefined)[]) {
  const indexContents = extractPathIndexText(pathSegment);
  if (indexContents == null) return '';
  if (isStrInt(indexContents) && Array.isArray(parentNode)) {
    return parentNode[parseInt(indexContents)]?.id ?? indexContents;
  }
  return indexContents;

}

/**Loose UUID check, does not check UUID version and that it complies. It is not uuid.validate
 *
 * @param testText
 * @returns
 */
export function isUuidForm(testText: string) {
  return testText.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i);
}

type snapshotLoadResult = {id: string, error?: string, success?: {[fileid:string]: any}, fileId: string};

type snapshotLoaderFunction = (fileid: string) => Promise<Record<string, any> | string>;

export function migrateTenantsToArray(draft: Snapshot & MaterialisedPropertyData) {
  const pfId = draft.id;
  if (!pfId) return false;
  if (!draft.saaTenant) return false;

  const migrationNeccessary = !Array.isArray(draft.tenantsCollect) || !draft.saaTenant.migrationLinkingTenantId;
  if (!migrationNeccessary) return false;

  // In order to keep this migration ID being the same as an actual migration, we need to use some
  // predictable data, that is still globally unique. So we use the already unique pf ID, and then
  // hash that with the 'tenant' text to indicate this is a migration. We don't have any real
  // namespace here, so we'll just past NIL.
  const migratedId = v5(`tenant-migrate-${pfId}`, NIL);

  const { tenantEnable: _unused, ...rest } = draft.saaTenant;

  draft.saaTenant.migrationLinkingTenantId = migratedId;
  if (!Array.isArray(draft.tenantsCollect)) {
    draft.tenantsCollect = [];
  }
  for (let idx = draft.tenantsCollect.length; idx > 0; idx--) {
    const item = draft.tenantsCollect[idx-1];
    const keys = Object.keys(item);
    if (keys.length > 1) continue;
    if (!keys[0] || keys[0] === 'id') {
      draft.tenantsCollect.splice(idx-1,1);
    }
  }
  draft.tenantsCollect.splice(0,0,{  ...rest, id: migratedId });

}
export function maskVariationWithFeeDefaults(snapshot: MaterialisedPropertyData | undefined | null, currentData: MaterialisedPropertyData | undefined): MaterialisedPropertyData|undefined|null {
  if (!snapshot?.professFee) return snapshot;
  if (!(currentData?.professFee?.enabledModes != null && snapshot.professFee.enabledModes == null)) return snapshot;
  return {
    ...snapshot,
    professFee: { ...snapshot.professFee, enabledModes: {
      fixedFee: { set: true, range: true },
      commis: { set: true, range: true }
    } }
  };
}

/**Take a snapshot and apply migrations as if it were already a new version
 *
 */
export function migrateSnapshotDataPreventFalsePositives(tree: MaterialisedPropertyData): MaterialisedPropertyData {
  const draft = structuredClone(tree);
  const falseFailure = migrateTenantsToArray(draft);
  if (falseFailure) {
    console.warn('Could not update snapshot');
  }
  return draft;
}

export function loadSnapshotFactory(snapshotReadAndCallback: snapshotLoaderFunction) {
  return async function (instance: FormInstance): Promise<snapshotLoadResult> {
    const fileid = instance?.signing?.session?.associatedFiles?.propertyDataSnapshot?.id;
    const result: snapshotLoadResult = { id: instance.id, error: 'unknown', fileId: fileid };
    if (!fileid) {
      result.error = 'No file ID present for snapshot';
      return result;
    }
    const objectSuccess = await snapshotReadAndCallback(fileid);
    if (!(objectSuccess && typeof objectSuccess === 'object')) {
      const errStr = typeof objectSuccess === 'string' ? objectSuccess : 'Unknown error';
      result.error = errStr;
      return result;
    }
    delete result.error;
    result.success = { [fileid]: objectSuccess };
    return result;
  };
}

export function staticHistoryBuilderFactory(loadSnapshot: (instance: FormInstance) => Promise<snapshotLoadResult>) {
  return async function(formStates: FormStates, instanceFamily: FormCode): Promise<Partial<InstanceHistory>> {
    const signedInstances = formStates?.[instanceFamily].instances?.filter(formCompleted);
    signedInstances?.sort((instanceA, instanceB) => {
    // ascending sort
      return (instanceA.signing?.session?.completedTime||0) - (instanceB.signing?.session?.completedTime||0);
    });
    if (!signedInstances || signedInstances.length === 0) {
      return { instanceList: [], data: {} };
    }

    const objectList = await Promise.all((signedInstances??[])
      .map(loadSnapshot));

    const instHist = { instanceList: signedInstances??[], data: Object.assign({}, ...objectList.map(r=>r.success).filter(Predicate.isNotNullish)) };
    return instHist;
  };
}
const selfOrNullish = Predicate.proxySelfOrNullish;

export function getProxyEmail(party: SigningParty | undefined) {
  return selfOrNullish(party?.proxyAuthority) ? party?.snapshot?.email : party?.proxyEmail;
}
export function getProxyPhone(party: SigningParty | undefined) {
  return selfOrNullish(party?.proxyAuthority) ? party?.snapshot?.phone : party?.proxyPhone;
}

export function getProxyName(party: SigningParty | undefined) {
  return selfOrNullish(party?.proxyAuthority) ? party?.snapshot?.name : party?.proxyName;
}

export function getProxyWithOriginalName(party: SigningParty | undefined | null) {
  return selfOrNullish(party?.proxyAuthority) ? party?.snapshot?.name : `${party?.proxyName} (as proxy for ${party?.snapshot?.name})`;
}
