import { EmailEvent, MaterialisedProperty, MaterialisedPropertyData, VendorParty, VendorPartyJoint } from '../../property';
import { z } from 'zod';
export * from './access-permissions';

export interface PropertiesSearchResultItem {
  id: string,
  primarySaleAddress: string;
  agents?: {
    id: string;
    name: string;
  }[];
  vendors?: (VendorParty | VendorPartyJoint)[];
  replaces?: string[];
  data?: MaterialisedProperty;
  lastAccessed?: number;
  created?: number;
  score?: number;
}

/**
 * Result of GET /properties
 */
export interface PropertiesSearchResult {
  items: PropertiesSearchResultItem[];
  pageNumber: number;
  pageSize: number;
  totalMatching: number;
}

/**
 * Params for GET /properties
 */
export const PropertiesSearchQueryParamsSchema = z.object({
  searchTerm: z.optional(z.string()),
  showArchived: z.optional(z.string().transform(x => x === 'true')),
  showStarred: z.optional(z.string().transform(x => x === 'true')),
  pageNumber: z.optional(z.coerce.number()),
  pageSize: z.optional(z.coerce.number())
});

export type PropertiesSearchQueryParams = z.infer<typeof PropertiesSearchQueryParamsSchema>;

export interface PropertiesRecentResultItem {
  id: string;
  timestamp: number;
}

export interface PropertiesRecentResult {
  items: PropertiesRecentResultItem[];
}

export interface PutPropertyFileRefParams {
  query: {
    contentType: string;
    formCode: string;
    formId: string;
    signingSessionId?: string;
    /**
     * If the file will be sent in a zip archive alongside a manifest.json,
     * then set this to application/zip
     * {file-id} (zip) containing
     * * manifest.json
     * * {file-id} (original file)
     * It will be unpacked after upload
     */
    transportContentType?: string;
  }
}

export interface UploadArchiveManifest {
  files: {
    id: string;
    contentType: string;
    data: unknown;
  }[]
}

export interface PutPropertyFileRefResult {
  url: string,
  headers?: {[key: string]: string}
}

export type HumanizedValueTokenChange = { type: 'change', from: HumanizedValueToken[], to: HumanizedValueToken[] };
export type HumanizedValueToken =
  | { type: 'text', text: string }
  | { type: 'value', text: string, mode?: 'ins' | 'del' }
  | { type: 'group' | 'list', identifyingText?: string, items: HumanizedValueToken[] }
  | HumanizedValueTokenChange;

export type PropertyAuditDetail = { headline: string } & (
  | { type: 'text' }
  | { type: 'sms', body: string, to: string, toName?: string }
  | EmailPropertyAuditDetail
  | { type: 'text', subtype?: EmailEvent }
  | { type: 'decline', reason: string }
  | ServePropertyAuditDetail
  | { type: 'diff', diff: {path: string, desc?: HumanizedValueToken}[] }); // Path is a bit misleading, it is rendered to text. Example: Changed Method

export type EmailPropertyAuditDetail = { type: 'email', body: string, to: string, toName?: string, from: string, subject: string };
export type ServePropertyAuditDetail = { type: 'serve', fileId?: string, to?: string };

export type PropertyAuditEntryType =
  | 'created'
  | 'deleted'
  | 'edited'
  | 'signed'
  | 'voided'
  | 'started'
  | 'declined'
  | 'executed'
  | 'email'
  | 'verified'
  | 'other'
  | 'terminated'
  | 'offer-declined'
  | 'offer-accepted'
  | 'offer-withdrawn'
  | 'offer-submitted'
  | 'offer-resubmitted'
  | 'sms'
  | 'portal-invitation-withdrawn'
  | 'served'
  | 'distributed'
  | 'set-access'
  | 'remove-access';

export interface PropertyAuditMetadata {
  phone?: string;
  email?: string;
  ip?: string;
  hostedBy?: string;
  proxyOnBehalfOf?: string;
  smsVerified?: boolean;
  copyAttached?: boolean;
  wetSignedDate?: string;
}

export interface PropertyAuditEntry {
  tsStart: number,
  tsEnd?: number,
  type: PropertyAuditEntryType,
  by: string,
  detail?: PropertyAuditDetail,
  meta: PropertyAuditMetadata
}

export type PropertyAuditEntryTypeCategory =
  | 'all'
  | 'content'
  | 'signing'
  | 'notification';

export type GetPropertyAuditEntriesRequestQuery = {
  startKey?: string;
  formFamily?: string;
  type: PropertyAuditEntryTypeCategory;
  before?: string;
  authorIds?: string;
  signingSessionId?: string;
};

export interface GetPropertyAuditEntriesResult {
  nextKey?: string;
  page: PropertyAuditEntry[];
}

export type GetPropertyAuditAuthorsRequestQuery = {
  formFamily?: string;
};

export interface GetPropertyAuditAuthorsResult {
  page: {
    id: string,
    label: string
  }[]
}

export interface GetPropertyAuditFormFamiliesResult {
  page: {
    id: string,
    label: string
  }[]
}

export interface GetSigningSessionHistoryResultItem {
  time: string,
  user: string,
  meta?: PropertyAuditMetadata,
  action: string,
  activity: string
}

export interface GetSigningSessionHistoryResult {
  envelope: {
    id: string,
    recipients: string,
    subject: string,
    document: string,
    createdAt: string,
    status: string,
    statusAt: string,
    timezone: string,
    initiator: string,
  },
  items: GetSigningSessionHistoryResultItem[]
}

export type PostRequestJobQuoteRequestBody = {
  source: 'epf';
  formId?: string;
  overridePropertyData?: MaterialisedPropertyData
};

export interface PostRequestJobQuoteResponse {
  fees: {
    label: string;
    quantity: number;
    value: string;
    total: string;
  }[];
  total: string;
  allowInstant: boolean;
  vendorPaidAdvertisingProvider: string | undefined;
  duplicates: {
    jobId: number;
    address: string;
    propertyFolderId?: string | null;
    formId?: string | null;
  }[];
}

export const PostRequestJobRequestBodySchema = z.object({
  source: z.enum(['epf']),
  formCode: z.string(),
  formId: z.string(),
  formSpecific: z.object({
    type: z.enum(['epf-form1']),
    useInstantSearches: z.boolean().optional(),
    // vq
    whoCompletes: z.enum(['SalesPerson', 'EckermannPropertyForms' , 'Vendor']),
    // arranges signing
    whoGets: z.enum(['SalesPerson', 'EckermannPropertyForms']),
    whoPays: z.enum(['Agency', 'Vendor', 'Provider']),
    whoServes: z.enum(['Agency'])
  }).required()
}).required();
export type PostRequestJobRequestBody = z.infer<typeof PostRequestJobRequestBodySchema>;

export interface PostRequestJobResponseBody {
  jobId: number,
  searches?: {
    queued: boolean,
    rateLimited: boolean
  }
}

export const PostJobReturnBodySchema = z.object({
  formCode: z.string(),
  formId: z.string(),
  message: z.string()
});

export type PostJobReturnBody = z.infer<typeof PostJobReturnBodySchema>;

export enum EntityImageType {
  PurchaserSelfRegistrationHeader = 'PurchaserSelfRegistrationHeader'
}

export const PostEmailFormBodySchema = z.object({
  to: z.array(z.string()),
  cc: z.optional(z.array(z.string())),
  bcc: z.optional(z.array(z.string())),
  message: z.optional(z.string())
});
export type PostEmailFormBody = z.infer<typeof PostEmailFormBodySchema>;
